<template>
  <ul class="site-menu wh100 boxScroll" v-infinite-scroll="load">
   
    <li 
    v-for="(item, index) in menuList" 
    :key="item.id" 
    @click="clickSubMenu(item)" 
    :class="['site-menu-item',isActiveMenu(item) ? 'menu-active-bg-image':'menuItemBg',item.code? 'cp':'cNotclick']">
     <div class="menu_left">               
      <span :class="['iconfont', icon(item.agrMachinerytInfoType),isActiveMenu(item)? 'baseColor':'','font-28']"></span>
      <div :class="[isActiveMenu(item)? 'baseColor':'']">
        <!-- class="sinleline" -->
        <div>
          <span>{{ item.agrMachinerytTypeName }}</span>
         
          <span :class="['status',item.status == 1 ? 'borderOff offLineColor' : 'borderBase baseColor']" v-if="item.status && item.code">{{ item.status == 1 ? '闲置' : '作业中'}}</span>
          <span v-else class="borderOff offLineColor status">未绑定GPS设备</span>
        </div>
        <div :class="[isActiveMenu(item)? 'baseColor':'']">{{item.machinerytNumber}}</div>
      </div>
    </div>
    <!-- <div class="baseColor cp font-12" @click="toDetails(item)">详情</div> -->
    </li>
  </ul>
</template>

<script>
// import CollapseTransition from '@/libs/collapse-transition';
import config from '@/config'
import { calculateCenter } from  '@/libs/util.js'
   import bus from '@/libs/eventBus'
export default {
  // components: {
  //   'collapse-transition': CollapseTransition,
  // },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    isSlot: {
      type: Boolean,
      default: false
    },
    menuList: {
      type: Array,
      default: () => []
    },
  
   
  },
  data () {
    return {
      isOpen: '',
      activeMenu: '',
      sortIcon: ['icon-paixu-jiangxu', 'icon-paixu-shengxu'],
      srotType: false,
      onlineData: config.devStatus.online,
      isInit: true,
      typeList:[
         {
          type: 1,
          icon: 'icon-xuangengji'
         },
         {
          type: 2,
          icon: 'icon-mti-wurenji'
         },
         {
          type: 3,
          icon: 'icon-bozhongji'
         },
         {
          type: 4,
          icon: 'icon-shougeji'
         }
         
      ],
      mockList: [
          {
              "agrMachinerytTypeId": "24090415064483802782",
              "agrMachinerytTypeName": "测试",
              "agrMachinerytInfoType": 1,
              "devModelNo": "阿事实上",
              "devPower": "150",
              "vendorId": "22011310091153872147",
              "code": "220225",
              "fuel": "采油",
              "size": "50;150;20",
              "companyId": "21092611431121603446",
              "enterpriseId": null,
              "status": null,
              "id": "24090509224404881007"
          }
      ]
    }
  },
  created () {
    bus.$on('changeMenuActive',id => {
         if(id == this.activeMenu) this.activeMenu = ''
    })
    // 考虑异步问题，下午监听 menus 解决
  
    // if(this.menuList && this.menuList.length && !this.activeMenu){
    //   this.activeMenu = this.menuList[0].id
    //   this.$emit('change', this.activeMenu)
    // }
  },
  destroyed(){
   
    // this.clearLocaInfo()
  },
  computed: {
   
    isActiveMenu () {
      return dev => {
        if (this.activeMenu == dev.id) return true
        else return false
      }
    },
   
  },
  methods: {
    toDetails(item){
      this.$emit('onCheck', item)
    },
    load () {
       
    },
    icon(type){
         let icon = ''
         this.typeList.some(item => {
            if(item.type == type){
              icon = item.icon
                return true
            }
         })
         return icon
    },
  
    clearLocaInfo(){
    
        localStorage.removeItem('activeAnimelMenu')
    },
    clickMenu (i) {

      if (this.isOpen === i.id) {
        this.isOpen = '';
      } else {
        this.isOpen = i.id;
      }

    },
    clickSubMenu (dev) {
      if(!dev.code) return 
      if(!dev.lng || !dev.lat){
        return this.$msg.info('暂无农机定位信息')
      }
      // localStorage.setItem('activeMenu',JSON.stringify(dev.animaId))
      this.activeMenu = dev.id
     
      this.$emit('change',dev)
 
    },

    updateArea(item) {
      let defaultPath = item.rangeLatitude
      let centerPosition = calculateCenter(JSON.parse(defaultPath))
      this.$emit('setCenter',centerPosition)
    },
    addSrotAttr () {
      this.menus.forEach(item => {
        if (!item.hasOwnProperty('isSort')) item.isSort = false
      })
      return this.menus
    },
    srotChange (data) {
      if (data.almInfos && data.almInfos.length > 1) {
        data.almInfos.reverse()
        data.isSort = !data.isSort
        this.srotType = true
        if (this.isOpen !== data.id) {
          this.isOpen = data.id
        } else {
          this.isOpen = ''
          setTimeout(() => {
            this.isOpen = data.id
          }, 500);
        }
      } else {
        // this.$msg.info('设备数量 < 2,无法排序！')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.site-menu {
  font-size: 14px;
  color: #fff;
  padding-right: 5px;
  
   
    .site-menu-item {
      width: 100%;
      // height: 40px;
      margin-top: 8px;
      padding: 10px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu_left{
        display: flex;
        align-items: center;
        & > span {
          display: block;
        
          flex-shrink: 0;
        }
        & > span:first-child {
          width: 30px;
          font-size: 20px;
          margin-right: 10px;
          // margin: 0 0 0 20px;
        }
        & > span:last-child {
          font-size: 18px;
          width: 24px;
          margin: 0 8px;
        }
        & > div {
          // flex: 1;
          min-width: 0;
          // max-width: 170px;
          & > div:first-child{
             display: flex;
             align-items: center;
             .status{
                font-size: 12px;
                // width: 50px;
                height: 16px;
                border-radius: 8px;
                padding: 0 10px;
                text-align: center;
                line-height: 16px;
                margin-left: 10px;
             }
          }
          & > div:last-child {
            width: 100%;
            color: #93959b;
            font-size: 12px;
          }
        }
      }
    }
   
  
  // & > li:not(:first-child) .menuName {
  //   margin-top: 6px;
  // }
  // & > li:first-child .menuName {
  //   border-radius: 8px 8px 0 0 !important;
  // }
  // & > li:last-child .menuName {
  //   border-radius: 0 0 8px 8px;
  // }
}
</style>