<template>
  <div class="windowInfoMainBox" ref="winInfo">
    <div class="top_box">
      <div>
        <span class="iconfont icon-title baseColor"></span>
        <span class="baseColor">农机详情</span>
      </div>
      <span class="el-icon-close cp" @click="colseClick"></span>
    </div>
    <div class="content_box" v-if="rawData">
      <div class="grid-content">
            <span>农机分类：</span>
            <span>{{ $formatter(rawData.agrMachinerytInfoType, "agrMachinerytInfoType")  || "无" }}</span>
      </div>
      <div class="grid-content">
            <span>农机状态：</span>
            <span :class="[rawData.status == 2 ? 'baseColor' : 'offLineColor']">{{rawData.status ? rawData.status == 1 ? '闲置' : "作业中" : "未绑定GPS设备" }}</span>
      </div>
      <div class="grid-content">
            <span>农机编号：</span>
            <span>{{ rawData.machinerytNumber || "无" }}</span>
      </div>
      <div class="grid-content">
            <span>农机类型：</span>
            <span>{{ rawData.agrMachinerytTypeName || "无" }}</span>
      </div>
      <div class="grid-content">
            <span>农机型号：</span>
            <span>{{ rawData.devModelNo || "无" }}</span>
      </div>
      <div class="grid-content">
            <span>农机功率：</span>
            <span>{{ rawData.devPower || "无" }}</span>
      </div>
      <div class="grid-content">
            <span>GPS状态：</span>
            <span :class="[this.rawData.positioning == 1? 'text-write': 'offLineColor']">{{ this.rawData.positioning == 1 ? 'GPS在线' : 'GPS信号弱'  }}</span>
      </div>
      <div class="grid-content">
            <span>燃油类型：</span>
            <span>{{ rawData.fuel ||  "无" }}</span>
      </div>
      <div class="grid-content">
            <span>农机尺寸：</span>
            <span>{{ sizeHandle }}</span>
      </div>
      <img :src="handleImg" alt="" class="img" v-if="rawData.img">
      <div class="but_group">
         
          <el-popover
            placement="top-start"
            width="324"
            trigger="click"
            >
            <DatePanel v-model="date" :show-time="showTime" @change="toDateChange" />
            <el-button type="primary" size="mini" slot="reference" plain>
              作业轨迹<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
          </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
 import DatePanel from '../dataPanel.vue';
  export default {
    props: {
      rawData: Object,
    },
    components:{
      DatePanel
    },
    // watch: {
    //   animeId: {
    //     handler(val) {
    //       console.log(val,'asdff')
    //       if (val) this.getTraTraAnimalInfoDetail();
    //     },
    //   },
    // },
    data() {
      return {
        animeInfo: null,
        // 组件使用的时间对象
        date: null,
        // 是否显示时分秒
        showTime: false,
      };
    },
    created(){
  
    },
    computed: {
      handleImg(){
         return this.$farmUrl + this.rawData.img
      },
      sizeHandle(){
        if(!this.rawData.size) return '无'
        let [l,w,h] = this.rawData.size.split(';')
        return `${l}cm(长) x ${w}cm(宽) x ${h}cm(高)`
      }
     
    },
    methods: {
      // toDateChange(){
      //    console.log(this.date)
      //    document.body.click();
      // },
      colseClick() {
        this.onClose();
      },
      // 获取动物信息
      
      async toDateChange() {
        const nowDate = this.$day(this.date).format('YYYY-MM-DD') 
        const res = await this.$api.IotGPSDeviceHistoryList({ sDate: nowDate,eDate: nowDate,code: this.rawData.code });
        console.log(res)
        if (res && res.length) {
          document.body.click()
          let path = res.map(item => [item.lng,item.lat])
          this.onParam({ id: this.rawData.id, path: path });
          this.onClose();
        } else {
          this.$msg.info("暂无农机轨迹");
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .windowInfoMainBox {
    padding: 20px 10px 0px 10px;
    background-color: rgba(#2E3936, 0.8);
    border-radius: 10px;
    font-size: 14px;
    width: 420px;
    position: relative;
    .top_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > div {
        display: flex;
        align-items: center;
        font-size: 16px;
        & > span:nth-child(1) {
          font-size: 24px;
          margin-right: 2px;
        }
        & > span:nth-child(3) {
          margin-left: 6px;
        }
      }
      & > span {
        font-size: 18px;
      }
    }
    .content_box {
      padding: 10px 5px 20px 20px;
      box-sizing: border-box;
      position: relative;
      .img{
        width: 180px;
        height: 120px;
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
    .but {
      width: 92px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      background: rgba(242, 245, 250, 0.1);
      border: 1px solid #f2f5fa;
      border-radius: 10px;
    }
    .cps:hover{
       cursor: pointer;
       border: 1px solid var(--el-color-primary);
       color: var(--el-color-primary);
       background: var(--el-color-primary-light-5);
    }
    .grid-content {
      display: flex;
      margin-top: 5px;
    }
    .but_group{
       display: flex;
       justify-content: flex-end;
       margin-top: 10px;
    }
  }
  .windowInfoMainBox::after {
    content: " ";
    display: block;
    border-style: solid;
    border-color: transparent;
    border-width: 10px 10px 10px 0px;
    border-right-color: rgba(#15232d, 0.5);
    position: absolute;
    left: -10px;
    top: 50px;
  }
</style>
