<template>
    <div class="map_box wh100" id="containerMap"></div>
</template>

<script>
    import AMapLoader from "@amap/amap-jsapi-loader";
    import transform from "@/libs/transformMap.js";
    import windowInfo from "../windowInfo";
    import bus from '@/libs/eventBus'
    var trafficIcons = {
        1: require('@/assets/imgs/agricultural/default/icon5.png'),
        2: require('@/assets/imgs/agricultural/default/icon6.png'),
        3: require('@/assets/imgs/agricultural/default/icon7.png'),
        4: require('@/assets/imgs/agricultural/default/icon8.png')
    };
    export default {
        props:{
            list:{
                type: Array,
                default:() => []
            },
            pointActiveInfo: Object
        },
        data(){
            return {
                AMap:null,
                map: null,
                infoWindow: null,
                layer:null,
                zoom: 16,
                simpInfo: null,
                markers: [],
            }
        },
        watch:{
            pointActiveInfo:{
                handler(val,oldVal){
                    
                    if(val) this.toOpenWindow()
                      
                },
                immediate: true
            }
        },
        created(){
            console.log(this.list,'list')
           this.$nextTick(() => {
              this.mapInit()
           })
        },
        onClose() {
          this.infoWindow.close();
        },
        onParam(info) {
        this.setPathSimp(info);
        },
        computed:{
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            isMapLayer() {
                return this.$store.state.user.userInfo?.mapLayer == 0 ? true : false;
            },
            delIcon(){
 
               return require(`@/assets/imgs/fence/${this.$themeCode}/del.png`)
            },
            arrowIcon(){

               return require(`@/assets/imgs/fence/${this.$themeCode}/arrow.png`)
            },
        },
        methods:{
            mapInit(lngLat) {
                window.forceWebGL = true;
                AMapLoader.load({
                key: "296001efb793b1e2631010fa1e8c0524", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: ["AMap.GltfLoader", "AMap.MoveAnimation"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                Loca: {
                    // 是否加载 Loca， 缺省不加载
                    version: "2.0", // Loca 版本，缺省 1.3.2
                },
                // AMapUI: {
                //   version: '1.1',
                //   plugins:['overlay/SimpleMarker']
                // },
                })
                .then((AMap) => {
                    this.global_websocket_connect();
                    this.global_websocket_receive_message(
                    "/topic/devGPSData." + this.$companyId,
                    (res) => {
                        let dataInfo = JSON.parse(res.body);
                        this.markers.some((marker) => {
                        if (marker.devId == dataInfo.id) {
                            marker.setPosition(transform.gTw(dataInfo.lng, dataInfo.lat));
                            return;
                        }
                        });
                    },
                    false
                    );
                    this.AMap = AMap;
                    lngLat = this.userInfo ? transform.gTw(this.userInfo.lng, this.userInfo.lat) : lngLat;
                    // lngLat = transform.gTw(100.990626, 37.083374);
              
                    const zoom = !this.userInfo.mapLevel || this.userInfo.mapLevel < 5 ? 16 : this.userInfo.mapLevel
                    let map = new AMap.Map("containerMap", {
                        zoom,
                        pitch: 40,
                        viewMode: '3D',
                        center: lngLat,
                    // zoom: 15.5,
                    // center: lngLat,
                    });
                    this.zoom = zoom
                    // console.log(map,'map')
                    this.map = map;
                    // map.on("click", (e) => {
                    // console.log(e);
                    // });
                    if (!lngLat) this.getIpPosition(AMap);

                    if (this.isMapLayer) {
                        let wms = new AMap.TileLayer.WMTS({
                            url: "http://t4.tianditu.gov.cn/img_w/wmts",
                            blend: false,
                            titleSize: 256,
                            params: {
                            Layer: "img",
                            Version: "1.0.0",
                            Format: "tiles",
                            TileMatrixSet: "w",
                            STYLE: "default",
                            tk: "5f736f7a2df74e65321db96c568a7e16",
                            },
                        });
                        wms.setMap(map);
                    }
                    this.setMarket()
                    // map.setFitView()
                    //  this.setPointInfo()
                    // this.getTraBaseAreaAnimalList();
                    // this.getTraFenceDetailed();

                    //
                })
                .catch((e) => {});
            },
            setPathSimp(info) {
                let AMap = this.AMap;
                let that = this;
                let { id, path } = info;
                let transformPath = [];
                if (path && path.length) {
                    transformPath = path.map((item) => transform.gTw(item[0], item[1]));
                }

                if (this.simpInfo && this.simpInfo[id]) {
                that.map.remove(this.simpInfo[id]);
                   delete this.simpInfo[id];
                }
                AMap.plugin("AMap.MoveAnimation", () => {
                //异步加载插件
                let lastIndex = transformPath.length - 1;
                var showIcon = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(28, 28),
                    // 图标的取图地址
                    image: this.arrowIcon,
                    // 图标所用图片大小
                    imageSize: new AMap.Size(28, 28),
                });
                var delIcon = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(28, 28),
                    // 图标的取图地址
                    image: this.delIcon,
                    // 图标所用图片大小
                    imageSize: new AMap.Size(28, 28),
                });

                // 绘制轨迹

                let polyline = new AMap.Polyline({
                    map: this.map,
                    path: transformPath,
                    showDir: true,
                    strokeColor: "#28F", //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 5, //线宽
                    // strokeStyle: "solid"  //线样式
                });
                let passedPolyline = new AMap.Polyline({
                    map: that.map,
                    // path: lineArr,
                    strokeColor: "#AF5", //线颜色
                    // strokeOpacity: 1,     //线透明度
                    strokeWeight: 5, //线宽
                    // strokeStyle: "solid"  //线样式
                });

                let marker = new AMap.Marker({
                    map: that.map,
                    position: transformPath[0],
                    // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
                    icon: showIcon,
                    offset: new AMap.Pixel(-15, -13),
                    // autoRotation: true,
                    // angle:0,
                });
                let delMarker = new AMap.Marker({
                    map: that.map,
                    position: transformPath[lastIndex],
                    // icon: "https://a.amap.com/jsapi_demos/static/demo-center-v2/car.png",
                    icon: delIcon,
                    offset: new AMap.Pixel(-15, -13),
                    // autoRotation: true,
                    // angle:0,
                });
                if (!this.simpInfo) this.simpInfo = {};

                this.simpInfo[id] = [marker, passedPolyline, polyline];
                marker.isAnimel = true;
                marker.on("click", function (e) {
                    if (e.target.isAnimel) {
                    marker.moveAlong(transformPath, { duration: 3000, autoRotation: true });
                    }
                });
                delMarker.on("click", function (e) {
                    that.map.remove([marker, delMarker, passedPolyline, polyline]);
                });
                marker.on("moving", function (e) {
                    passedPolyline.setPath(e.passedPath);
                    // this.map.setCenter(e.target.getPosition(),true)
                });
                marker.on("movealong", function (e) {
                    e.target.isAnimel = false;
                    // marker.setIcon(delIcon)
                    that.map.remove(marker);
                });
                });
            },
            isPoint(px) {
                    const allWidth = document.body.clientWidth;
                    let times = 1920 / allWidth;
                    let pixelarr = px.toArray();
                    let pixel = [pixelarr[0] * times, pixelarr[1] * times];
                    var lnglat = this.map.containerToLngLat(pixel);
                    if (lnglat) return lnglat;
            },
            initInfoWindow(point,rawData) {
                
              
                const $dome = windowInfo.call(this, {
                    rawData,
                });
                //鼠标点击marker弹出自定义的信息窗体
                let infoWindow = new this.AMap.InfoWindow({
                    isCustom: true, //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
                    content: $dome,
                    closeWhenClickMap: false,
                    anchor: new AMap.Pixel(0,0),
                    offset: new AMap.Pixel(50, -80),
                });

                infoWindow.open(this.map, point);
                infoWindow.on('close',() => {
                    this.showInfoClose(rawData)
                })
                this.infoWindow = infoWindow;
                this.$nextTick(() => {
                    this.map.setCenter(point);
                })
            },
            showInfoClose(row){
                console.log(row)
                bus.$emit('changeMenuActive',row.id)
            },
            toOpenWindow(){
              
                let lngLat = transform.gTw(this.pointActiveInfo.lng, this.pointActiveInfo.lat)
                this.setCenter(lngLat)
                this.markers.map(marker => {
                         
                         if(marker.devId == this.pointActiveInfo.id){
                          
                            this.initInfoWindow(lngLat,this.pointActiveInfo)
                         }
                })
                // var pixel = this.map.lngLatToContainer(arr);
                // console.log(pixel)
                // let breathfeat = this.isPoint(pixel);
                // console.log(breathfeat)
                // if(breathfeat){
                   
              
                //     this.initInfoWindow(breathfeat);
                // }
            },
             // 更新中心点位
            setCenter(point){
                
                if(this.map) this.map.setCenter(point)
            },
            // 获取ip定位信息
            getIpPosition(AMap) {
                AMap.plugin("AMap.Geolocation", function () {
                new AMap.Geolocation({
                    // 是否使用高精度定位，默认：true
                    enableHighAccuracy: true,
                    // 设置定位超时时间，默认：无穷大
                    timeout: 10000,
                    // 定位按钮的停靠位置的偏移量
                    offset: [10, 20],
                    //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                    zoomToAccuracy: true,
                    //  定位按钮的排放位置,  RB表示右下
                    position: "RB",
                });
                });
            },
         
            // 设置market
            setMarket() {
                let AMap = this.AMap;
                if (this.markers.length) {
                    this.map.remove(this.markers);
                    this.markers = [];
                }
                this.list.map((item) => {
                if (!item.lng || !item.lat) return;
             

                // let [lng, lat] = item.currPosition.split(",");
                let animeIcon =  trafficIcons[item.agrMachinerytInfoType]
                
                // 创建一个 Icon
                var startIcon = new AMap.Icon({
                    // 图标尺寸
                    size: new AMap.Size(38, 47),
                    // 图标的取图地址
                    image: animeIcon,
                    // 图标所用图片大小
                    imageSize: new AMap.Size(38, 47),
                });
                var marker = new AMap.Marker({ zIndex: 120 });
                // [item.lng,item.lat] transform.gTw(104.063321,30.594767) transform.gTw(item.lng,item.lat)

                marker.setPosition(transform.gTw(item.lng, item.lat));
                marker.setMap(this.map);
                marker.setIcon(startIcon);
                marker.devId = item.id;
                marker.dataInfo = item;
                this.markers.push(marker);
                let _that = this;
                marker.on("click", (e) => {
                    let point = _that.isPoint(e.pixel);
                    if(point){ _that.initInfoWindow(point,item); }
                   
                
                    
                

                    // let point = this.isPoint(e);
                    // this.map.setCenter(point);
                    // const $dome = windowInfo.call(_that, {
                    // animeId: item.animaId,
                    // pointInfo: item,
                    // });

                    // //鼠标点击marker弹出自定义的信息窗体
                    // let infoWindow = new AMap.InfoWindow({
                    // isCustom: true, //使用自定义窗体  windowInfo(e.target.orderno.deviceDataInfos)
                    // content: $dome,
                    // closeWhenClickMap: false,
                    // anchor: new AMap.Pixel(0, 0),
                    // offset: new AMap.Pixel(50, -50),
                    // });

                    // infoWindow.open(this.map, point);

                    // _that.infoWindow = infoWindow;
                });
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
.map_box{
    
}
</style>