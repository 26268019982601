<template>
    <div class="navSearch_main_box">
        <div class="search_top">
          
            <div class="search_box pr">
                    <el-input
                        class="inline-input"
                        v-model="reqData.machinerytNumber"
                        clearable
                        ref="cautocomplete"
                        placeholder="请输入农机编号"
                        @clear="clearSearch"
                    >
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    <template slot-scope="{ item }">
                            <div class="auto_select_box">
                                <span :class="['iconfont', 'icon-tongshe-erbiao', 'baseColor','autoIcon']"></span>
                                <div class="info_box">
                                    <p>{{ item.iotDevCode || item.animaId}}</p>
                                </div>
                                <!-- <p>无数据</p> -->
                            </div>
                        </template>
                    </el-input>
                    
                  
                    
            </div>
            <el-button size="mini" class="search_but" @click="toSearch" plain round>搜索</el-button>
        </div>
        <div class="filter_box">
            <el-select v-model="reqData.status"  @change="statusChange" size="medium" >
                <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
            <el-select v-model="reqData.agrMachinerytInfoType"  @change="typeChange" size="medium" class="ml-20">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
        </div>
        <template v-if="filterMenuList.length">
            <!--     :groupState='groupState' -->
            <Menu class="menu_box" 
            v-bind="$attrs" 
            v-on="$listeners" 
            :menuList="filterMenuList"></Menu>  
       </template>
       <template v-else>
           <div class="mt100">
                <hn-emptyData :text= "emptyText"  size= '160px' setType= 'col'></hn-emptyData>
           </div>
             
       </template>
       
      
    </div>
</template>

<script>
    import Menu from './Menu.vue'
    // import config from '@/config'
    // import { deepClone } from '@/libs/util.js'
    export default {
        name: 'HnMenu',
        components:{
            Menu,
        },
        props:{
          menuList:{
              type: Array,
              default: () => []
          },
          baseId: String

        },
        watch:{
            
            menuList: {
                handler (val) {
                    console.log(val,'watch')
                    this.filterMenuList = val
                }
            }
        },
        data(){
            return{         
               
               reqData:{
                 status: 'all',
                 agrMachinerytInfoType: 'all',
                 machinerytNumber: ''
               },
               typeOptions:[{
                     value: 'all',
                     label: '全部'
                }],
               statusOptions:[
                  {
                     value: 'all',
                     label: '全部'
                  },
                  {
                    value: '1',
                    label: '闲置'
                  },
                  {
                    value: '2',
                    label: '作业中'
                  }
               ],
               filterMenuList: []     
            }
        },
        created(){
             const dicList = this.$dictionary("agrMachinerytInfoType")
             this.typeOptions = this.typeOptions.concat(dicList)
            //  this.reqMenu()
        },
        computed: {
            
            // groupContent() {
            //    return this.groupState ? '基地菜单' : '分组菜单'
            // },
            emptyText() {
               return '暂无数据'
            },
            // groupIcon() {
            //    return this.groupState ? 'icon-tongwu-jidi1' : 'icon-tongwu-fenzu' 
            // },
            // defaultApi() {
            //     return this.groupState ? 'BaseDeviceList' : 'GroupDeviceList'
            // },
        
        },
        methods:{
            filterListFun(){
                
                  let ruselt =  this.menuList.filter(item => {
                      let { status, agrMachinerytInfoType, machinerytNumber} = this.reqData
                      if(machinerytNumber){
                        return (item.machinerytNumber.indexOf(machinerytNumber) != -1) 
                      }else{
                        const isDobold = (agrMachinerytInfoType || agrMachinerytInfoType !== 'all') && (status || status !== 'all')
                        if(isDobold && item.agrMachinerytInfoType == agrMachinerytInfoType && item.status == status) return true
                        if(item.agrMachinerytInfoType == agrMachinerytInfoType) return true
                        if(item.status == status) return true
                        
                      }
                      return true
                 })
                 return ruselt
            },
            toSearch(){
                this.reqData.status = 'all'
                this.reqData.agrMachinerytInfoType = 'all'
                this.reqMenu()
                // this.filterMenuList = this.filterListFun()
            
            },
         
            statusChange(){
                this.reqData.machinerytNumber = ''
                this.filterMenuList = this.filterListFun()
                this.reqMenu()
            },
            typeChange(){
                this.reqData.machinerytNumber = ''
                this.filterMenuList = this.filterListFun()
                this.reqMenu()
            },
            clearSearch() {
                // this.filterMenuList = this.filterListFun()
                this.reqMenu()
            },
            async reqMenu() {
                let handleData = {
                    status: this.reqData.status == 'all' ? '' : this.reqData.status ,
                    agrMachinerytInfoType: this.reqData.agrMachinerytInfoType == 'all' ? '' : this.reqData.agrMachinerytInfoType
                }
                let reqData = Object.assign({},this.reqData,handleData)
                const res = await this.$api.TraAgrMachinerytInfoList(reqData)
                if(res && res.length){
                    this.filterMenuList = res 
                    // this.menuList = this.reqData.pageIndex == 1 ? res.list : this.menuList.concat(res.list)
                }else{
                    this.filterMenuList = [] 
                }  
               
            },
           
           
         
          
        }
    }
</script>

<style lang="scss" scoped>
 .navSearch_main_box{
    flex: 1;
    display: flex;
    flex-direction: column;
    .search_top{
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        .search_but{
            flex-shrink: 0;
            height: 26px;
            line-height: 0;
        }
        .search_box{
        // width: 100%;
            flex: 1;
            height: 40px;
            border-bottom: 1px solid #DCDFE6;
     
        
        .grounpIcon{
            position: absolute;
            right: 0;
            top: 9px;
            font-size: 20px;
        }
        }
    }
    
    .filter_box{
        display: flex;
        margin-bottom: 15px;
    }
    .menu_box{
        // height: calc(100% - 50px);
        flex: 1;
        min-height: 0;
    }
    .el-autocomplete{
        width: 100%;
    }
    .inline-input ::v-deep .el-input__inner{
        background: none;
        border: none;
        color: #fff;
    }
    .mt100{
        margin-top: 100px;
    }
    .inline-input{
        // width: 255px;
    }
  
 }
</style>