<template>
    <div class="agricultural_main_box wh100 pr">
      <HnContent :hasData="hasData">
        <template slot="left">
          <div class="left_content_box">

         
            <areaMenu :menuList="menuList" @change="menuChange" @onCheck="toCheck" ></areaMenu>
            <div class="agriculturalBg info_box">
                 <div>
                   <p class="baseColor"><span class="point active"></span><span class="font-16">{{ devLength }}</span><span>台</span> </p>
                   <p>合计</p>
                 </div>
                 <div>
                   <p class="baseColor"><span class="point active"></span><span class="font-16">{{ idleNub }}</span><span>台</span> </p>
                   <p>闲置</p>
                 </div>
                 <div>
                   <p class="baseColor"><span class="point active"></span><span class="font-16">{{ onlineNub }}</span><span>台</span> </p>
                   <p>作业中</p>
                 </div>
            </div>
          </div>
        </template>
        <template slot="right">
          <div class="right_content_box wh100 video-box-bg-image pr"> 
              <div class="info-tips">
                 <div v-for="item in typeInfo" :key="item.name">
                    <img :src="item.icon" alt="">
                    <p>{{ item.name }}</p>
                 </div>
              </div>
              <mapBox :list="filterGpsDevList" :pointActiveInfo="pointActiveInfo"></mapBox>
          </div>
        </template>
      </HnContent>
    </div>
</template>

<script>
    import areaMenu from './components/areaMenu'
    import mapBox from './components/mapBox'
    export default {
        components: { areaMenu,mapBox },
        data(){
           return {
              hasData: true,
              typeInfo:[
                {
                   icon: require('../../../assets/imgs/agricultural/default/icon9.png'),
                   name: '旋耕机'
                },
                {
                   icon: require('../../../assets/imgs/agricultural/default/icon2.png'),
                   name: '管理机械'
                },
                {
                   icon: require('../../../assets/imgs/agricultural/default/icon3.png'),
                   name: '播种机'
                },
                {
                   icon: require('../../../assets/imgs/agricultural/default/icon4.png'),
                   name: '收割机'
                }
              ],
              menuList: [],
              pointActiveInfo: null
           }
        },
        created(){
          
             this.reqMenu()
        },
        computed:{
           filterGpsDevList(){
              return this.menuList.filter(item => item.code)
           },
           devLength(){
               return this.menuList ?.length || 0
           },
           idleNub(){
              let arr = this.filterGpsDevList.filter(item => item.status == 1)
              return arr.length
           },
           onlineNub(){
               let arr = this.filterGpsDevList.filter(item => item.status == 2)
              return arr.length
           }
        },
        methods:{
          async reqMenu() {
            const res = await this.$api.TraAgrMachinerytInfoList()
            if(res && res.length){
                
                this.menuList = res
            } 
               
          },
          toCheck(item){
             
          },
          menuChange(info){
        
              this.pointActiveInfo = info 
          },
          toBaseChange(){

          },
          setCenter(){

          }
        }
    }
</script>

<style lang="scss" scoped>
.agricultural_main_box{
  .right_content_box {
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
    .info-tips{
       width: 380px;
       height: 40px;
       position: absolute;
       left: 40px;
       top: 40px;
       background: rgba(0,0,0,0.5);
       border-radius: 10px;
       display: flex;
       align-items: center;
       z-index: 1000;
       & > div{
         flex: 1;
         display: flex;
         align-items: center;
         font-size: 12px;
         justify-content: center;
          img{
            margin-right: 8px;
          }
       }
    }
  }
  .info_box{
    width: 270px;
    height: 86px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    & > div{
       flex: 1;
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       font-size: 12px;
       & > p:first-child{
        display: flex;
        align-items: center;
          .point{
            width: 4px;
            height: 4px;
            border-radius: 2px;
            margin-right: 6px;
          }
       }
    }
  }
  .left_content_box{
    height: 100%;
     display: flex;
     flex-direction: column;
  }
}
</style>